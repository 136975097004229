import { useLocalStorage } from "@vueuse/core";
import { NativeAction } from "~~/src/enums";

const router = useRouter();
const route = useRoute();

export const useMenuStore = defineStore("menuStore", {
	state: () => ({
		SelectMainMenu: useLocalStorage("SelectMainMenu", "monitoring"),
		SelectInTabMenu: useLocalStorage("SelectInTabMenu", "all"),
		mainMenuList: useLocalStorage("mainMenuList", []),
		inTabMenuList: useLocalStorage("inTabMenuList", ""),
	}),
	// hydrate(state, initialState) {
	// 	state.SelectMainMenu = useLocalStorage("SelectMainMenu", initialState.SelectMainMenu);
	// 	state.SelectInTabMenu = useLocalStorage("SelectInTabMenu", initialState.SelectInTabMenu);
	// 	state.mainMenuList = useLocalStorage("mainMenuList", initialState.mainMenuList);
	// 	state.inTabMenuList = useLocalStorage("inTabMenuList", initialState.inTabMenuList);
	// },
	actions: {
		getMainMenuList() {},
		getInTabMenuList() {},
    initSelectMenu(userType: string){
      if(userType === 'owner'){ // 설치자
        switch(true){
          case route.fullPath.includes('monitoring'):       this.SelectMainMenu = 'monitoring'; break;
          case route.fullPath.includes('equipInfo'):        this.SelectMainMenu = 'equipInfo'; break;
          case route.fullPath.includes('powerGeneration'):  this.SelectMainMenu = 'powerGeneration'; break;
          case route.fullPath.includes('report'):           this.SelectMainMenu = 'report'; break;
          case route.fullPath.includes('more'):             this.SelectMainMenu = 'more'; break;
          default: this.SelectMainMenu = 'monitoring'; break;
        }
      } else { // 업체
        switch(true){
          case route.fullPath.includes('InstruInfo'):       this.SelectMainMenu = 'InstruInfo'; break;
          case route.fullPath.includes('SpecInfo'):         this.SelectMainMenu = 'SpecInfo'; break;
          case route.fullPath.includes('event'):            this.SelectMainMenu = 'event'; break;
          case route.fullPath.includes('powerGeneration'):  this.SelectMainMenu = 'powerGeneration'; break;
          case route.fullPath.includes('more'):             this.SelectMainMenu = 'more'; break;
          default: this.SelectMainMenu = 'monitoring'; break;
        }
      }
    },
		setSelectMainMenu(mainmenu: string) {
			const { $invokeNativeAction } = useNuxtApp();

			try {
				// @ts-ignore
				$invokeNativeAction(NativeAction.HapticClick);
			} catch {
			}
			
			this.SelectMainMenu = mainmenu;
		},
		setSelectInTab(intab: string) {},
	},
});
